<template>
	<el-dialog class="dialog" :title="dialogObj.type==1?'新增':'编辑'" :visible.sync="visiable" width="50%" append-to-body>
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px" :rules="rules">
				<el-row>
					<el-col :span="12" v-if="dialogObj.type==2">
						<el-form-item label="ID" prop="id">
							<el-input v-model="formData.id"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="测点名称" prop="name">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="类型" prop="modelType">
							<el-select v-model="formData.modelType" placeholder="请选择">
								<el-option label="振动传感器" :value="2">
								</el-option>
								<el-option label="温度传感器" :value="3">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="是否隐藏" prop="isDelete">
							<el-radio-group v-model="formData.isDelete">
								<el-radio :label="1">是</el-radio>
								<el-radio :label="0">否</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="坐标X" prop="pointX">
							<el-input v-model="formData.pointX"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="坐标Y" prop="pointY">
							<el-input v-model="formData.pointY"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="坐标Z" prop="pointZ">
							<el-input v-model="formData.pointZ"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="角度X" prop="rotaX">
							<el-input v-model="formData.rotaX"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="角度Y" prop="rotaY">
							<el-input v-model="formData.rotaY"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="角度Z" prop="rotaZ">
							<el-input v-model="formData.rotaZ"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="缩放X" prop="scaleX">
							<el-input v-model="formData.scaleX"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="缩放Y" prop="scaleY">
							<el-input v-model="formData.scaleY"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="缩放Z" prop="scaleZ">
							<el-input v-model="formData.scaleZ"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="透明度" prop="opacity">
							<el-input v-model="formData.opacity"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="已关联测点">
							<el-select v-model="formData.measId" placeholder="请选择">
								<el-option v-for="item in meaList" :key="item.name" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="拉线起点X" prop="startX">
							<el-input v-model="formData.startX"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="拉线起点Y" prop="startY">
							<el-input v-model="formData.startY"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="拉线起点Z" prop="startZ">
							<el-input v-model="formData.startZ"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="拉线终点X" prop="endX">
							<el-input v-model="formData.endX"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="拉线终点Y" prop="endY">
							<el-input v-model="formData.endY"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="拉线终点Z" prop="endZ">
							<el-input v-model="formData.endZ"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="备注">
							<el-input v-model="formData.remark"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">提交</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				formData: {},
				loading: false,
				meaList: [],
				rules: {
					id: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					name: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					modelType: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					pointX: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					pointY: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					pointZ: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					rotaX: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					rotaY: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					rotaZ: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					scaleX: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					scaleY: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					scaleZ: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					opacity: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					startX: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					startY: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					startZ: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					endX: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					endY: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					endZ: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					isDelete: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
				}
			};
		},
		props: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				})
				this.getmeaList();
				if (this.dialogObj.type == 2) {
					this.get();
				}
			},
			getmeaList() {
				if (this.dialogObj.unitId) {
					this.loading = true;
					this.$get("/backend-api/web/line/listMeas", {
						unit_id: this.dialogObj.unitId
					}).then((res) => {
						if (res.code == 1000) {
							this.meaList = res.data;
							this.loading = false;
						} else {
							this.$message.error(res.msg);
							this.loading = false;
						}
					});
				}
			},
			get() {
				this.$get("/backend-api/model/unit-meas/get", {
					id: this.dialogObj.row.id
				}).then((res) => {
					if (res.code == 1000) {
						this.formData = res.data;
						this.loading = false;
						if (!this.dialogObj.unitId) {
							this.formData.measId = "";
						}
					} else {
						this.$message.error(res.msg);
						this.loading = false;
					}
				});
			},
			submit() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.formData.unitTypeId = this.dialogObj.unitTypeId;
						this.$postData("/backend-api/model/unit-meas/save", this.formData).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.$parent.get();
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					}
				});
			},
			//关闭弹窗
			close() {
				this.loading = false;
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.el-dialog {
		min-width: 600px;
	}
</style>
