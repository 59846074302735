<template>
		<div class="flex" style="flex-direction: column;height: 100%;overflow: hidden;">
			<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
				<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
					<el-row class="flex-1">
						<el-col :span="6">
							<el-form-item label="供应商：">
								<el-select v-model="searchParm.brandId" placeholder="请选择品牌">
									<el-option v-for="item in options3" :key="item.id" :label="item.name"
										:value="item.id"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="输入搜索："><el-input v-model="searchParm.keyword"
									placeholder=""></el-input></el-form-item>
						</el-col>
						<el-col :span="10">
							<el-row>
								<el-form-item label="商品分类：">
									<el-col :span="10">
										<el-select v-model="searchParm.category1Id" @change="category1IdChange"
											placeholder="请选择商品类别">
											<el-option v-for="item in options" :key="item.id" :label="item.name"
												:value="item.id"></el-option>
										</el-select>
									</el-col>
									<el-col :span="2" style="text-align: center;"
										v-show="options2.length > 0"><span>-</span></el-col>
									<el-col :span="10" v-show="options2.length > 0">
										<el-select v-model="searchParm.category2Id" placeholder="请选择商品类别">
											<el-option v-for="item in options2" :key="item.id" :label="item.name"
												:value="item.id"></el-option>
										</el-select>
									</el-col>
								</el-form-item>
							</el-row>
						</el-col>
					</el-row>
					<div>
						<el-button type="primary" @click="list()">查询</el-button>
						<el-button plain @click="clearSearch()">重置</el-button>
					</div>
				</el-form>
			</sm-card>
			<div class="tableModule">
				<div class="tableContainer">
					<div class="table" ref="tableContainer">
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ padding: 0 }"
							style="margin-bottom: 20px;" v-loading="isLoading">
							<el-table-column prop="id" align="center" label="货号"
								show-overflow-tooltip></el-table-column>
							<el-table-column prop="brandName" align="center" label="供应商"
								show-overflow-tooltip></el-table-column>
							<el-table-column align="center" label="商品图片">
								<template slot-scope="scope">
									<img class="goodImg" :src="photoURL + scope.row.rentImg" alt="" />
								</template>
							</el-table-column>
							<el-table-column prop="name" align="center" label="商品名称"
								show-overflow-tooltip></el-table-column>
							<el-table-column align="center" label="分类" show-overflow-tooltip>
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper">
										{{ scope.row.category1Name + '-' + scope.row.category2Name }}</div>
								</template>
							</el-table-column>
							<el-table-column align="center" label="状态" show-overflow-tooltip>
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper"
										:style="{color:scope.row.sale == 0? '#ff460e':''}">
										{{ scope.row.sale == 1 ? '上架':'下架'}}</div>
								</template>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="230">
								<template slot-scope="scope">
									<el-select v-model="scope.row.skuName"  placeholder="请选择" @change="change(scope.$index,scope.row.name,$event)">
									    <el-option
									      v-for="item in scope.row.skus"
									      :key="item.id"
									      :label="item.number"
									      :value="item">
									    </el-option>
									  </el-select>
								</template>
							</el-table-column>
							<template slot="empty">
								<el-empty :image-size="100" description="暂无数据"></el-empty>
							</template>
						</el-table>
					</div>
					<div class="pagination">
						<el-pagination style="float: right;" @current-change="handleCurrentChange"
							:current-page.sync="searchParm.current" :page-size="searchParm.size"
							layout="total, prev, pager, next,jumper" :total="tableData.total"></el-pagination>
					</div>
				</div>
			</div>
		</div>
</template>
<script>
	import smCard from '@c/smCard.vue';
	export default {
		components: {
			smCard
		},
		data() {
			return {
				tableHeight: 0,
				provinces: [],
				activeName: '1',
				options: [],
				options2: [],
				options3: [],
				tableData: {
					records: []
				},
				searchParm: {
					size: 10,
					current: 1,
					type: '',
					category1Id: '',
					category2Id: ''
				},
				showEditDialog: false,
				editObj: {},
				isLoading: false,
				valueObj:{},
				oldIndex:null
			};
		},
		methods: {
			change(index,name,val){
				this.valueObj ={
					rentSkuInfo:name+'-'+val.number,
					rentSkuId:val.id,
					rentId:val.rentId
				}
				if(this.oldIndex!=null){
					this.tableData.records[this.oldIndex].skuName = '';
				}
				this.oldIndex = index;
				this.tableData.records[index].skuName = val.number;
			},
			showView(){
				this.calHeight();
				window.addEventListener('resize', this.calHeight);
				if (this.$route.params.type) {
					this.searchParm.type = this.$route.params.type;
					this.list();
				} else {
					this.list();
				}
				this.getCategory('01', 1);
				this.getBrand()
				this.valueObj ={}
			},
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect();
					this.tableHeight = rect.height;
				});
			},
			handleChange(value) {
				this.searchParm.type = value;
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					size: 10,
					current: 1,
					type: '',
					category1Id: '',
					category2Id: ''
				};
				this.list()
			},
			// 类目一选择
			category1IdChange(e) {
				this.getCategory(e, 2);
				this.options2 = [];
				this.searchParm.category2Id = ''
			},
			//获取分类
			getCategory(e, type) {
				this.$get('/shop-api/category/mgmt/listByPid?pid=' + e).then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					if (type == 1) {
						this.options = res.data;
					} else {
						this.options2 = res.data;

					}
				});
			},
			//获取品牌
			getBrand() {
				this.$get('/shop-api/brand/mgmt/list').then(res => {
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.options3 = res.data;
				});
			},
			//展示数据
			list() {
				this.$get('/shop-api/mgmt/rent/list', this.searchParm)
					.then(res => {
						if (res.code == 1000) {
							this.tableData = res.data;
						} else {
							this.$message.error(res.msg);
						}
					})
					.catch(err => {
						//请求失败，关闭loading
						this.isLoading = false;
					});
			},
			
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
			//关闭弹窗
			close() {
				this.loading = false;
				this.visiable = false
			}
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight);
		}
	};
</script>
<style lang="scss" scoped>
	@import '@style/userCenter.scss';

	.goodImg {
		width: 80px;
		height: 80px;
		margin-top: 5px;
	}
</style>