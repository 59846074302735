<template>
	<el-dialog class="dialog" :title="dialogObj.type==1?'新增单体模型':'编辑单体模型'" :visible.sync="visiable" width="60%"
		append-to-body>
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px" :rules="rules">
				<el-row>
					<el-col :span="8" v-if="dialogObj.type==2">
						<el-form-item label="ID" prop="id">
							<el-input v-model="formData.id"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="16">
						<el-form-item label="名称/型号" prop="name">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="分区" prop="group">
							<el-input v-model="formData.group"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="类型" prop="compType">
							<el-select v-model="formData.compType" placeholder="请选择">
								<el-option label="齿轮" :value="1">
								</el-option>
								<el-option label="滚动轴承" :value="2">
								</el-option>
								<el-option label="轴" :value="3">
								</el-option>
								<el-option label="其他" :value="-1">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="图号" prop="compTypeId">
							<el-input v-model="formData.compTypeId"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<!-- <el-row>
					<el-col :span="8">
						<el-form-item label="图号" prop="compTypeId">
							<el-input v-model="formData.compTypeId"></el-input>
						</el-form-item>
					</el-col>
				</el-row> -->
				<el-row>
					<el-col :span="8">
						<el-form-item label="坐标X" prop="pointX">
							<el-input v-model="formData.pointX"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="坐标Y" prop="pointY">
							<el-input v-model="formData.pointY"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="坐标Z" prop="pointZ">
							<el-input v-model="formData.pointZ"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="角度X" prop="rotaX">
							<el-input v-model="formData.rotaX"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="角度Y" prop="rotaY">
							<el-input v-model="formData.rotaY"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="角度Z" prop="rotaZ">
							<el-input v-model="formData.rotaZ"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="缩放X" prop="scaleX">
							<el-input v-model="formData.scaleX"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="缩放Y" prop="scaleY">
							<el-input v-model="formData.scaleY"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="缩放Z" prop="scaleZ">
							<el-input v-model="formData.scaleZ"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="透明度" prop="opacity">
							<el-input v-model="formData.opacity"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="排序" prop="sort">
							<el-input v-model="formData.sort"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="是否旋转" prop="rotateStatus">
							<el-radio-group v-model="formData.rotateStatus">
								<el-radio :label="1">是</el-radio>
								<el-radio :label="0">否</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="8" v-if="formData.rotateStatus==1">
						<el-form-item label="旋转轴" prop="rotateShaft">
							<el-input v-model="formData.rotateShaft"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8" v-if="formData.rotateStatus==1">
						<el-form-item label="旋转速度" prop="rotateSpeed">
							<el-input v-model="formData.rotateSpeed"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="部件坐标X" prop="compPointX">
							<el-input v-model="formData.compPointX"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="部件坐标Y" prop="compPointY">
							<el-input v-model="formData.compPointY"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="部件坐标Z" prop="compPointZ">
							<el-input v-model="formData.compPointZ"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="部件角度X" prop="compRotaX">
							<el-input v-model="formData.compRotaX"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="部件角度Y" prop="compRotaY">
							<el-input v-model="formData.compRotaY"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="部件角度Z" prop="compRotaZ">
							<el-input v-model="formData.compRotaZ"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="部件缩放X" prop="compScaleX">
							<el-input v-model="formData.compScaleX"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="部件缩放Y" prop="compScaleY">
							<el-input v-model="formData.compScaleY"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="部件缩放Z" prop="compScaleZ">
							<el-input v-model="formData.compScaleZ"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="按钮展示" prop="button">
							<el-radio-group v-model="formData.button">
								<el-radio :label="1">展示</el-radio>
								<el-radio :label="0">不展示</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="模型隐藏" prop="isDelete">
							<el-radio-group v-model="formData.isDelete">
								<el-radio :label="1">是</el-radio>
								<el-radio :label="0">否</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="模型颜色" prop="color">
							<el-color-picker v-model="formData.color"></el-color-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="备注">
							<el-input type="textarea" :rows="2" v-model="formData.remark"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="关联商品">
							<el-input v-model="formData.goodsSkuInfo" @focus="focusValue"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">提交</el-button>
			</div>
		</div>
		<el-dialog title="选择商品关联模型" :visible.sync="selectGoodsDialog" width="80%" append-to-body>
			<selectGoods ref="child" style="height: 60vh;"></selectGoods>
			<div class="dialog-footer" style="display: flex;justify-content: flex-end;">
				<el-button @click="cancel()">取消</el-button>
				<el-button type="primary" @click="determine()">确定</el-button>
			</div>
		</el-dialog>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	import selectGoods from "./selectGoods.vue";
	export default {
		mixins: [dialogMixin],
		components: {
			selectGoods
		},
		data() {
			return {
				formData: {},
				loading: false,
				selectGoodsDialog: false,
				rules: {
					id: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					name: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					group: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					compTypeId: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					compType: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					// pointX: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// pointY: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// pointZ: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// rotaX: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// rotaY: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// rotaZ: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					scaleX: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					scaleY: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					scaleZ: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					opacity: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					sort: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					rotateStatus: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					rotateShaft: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					rotateSpeed: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					button: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					// compPointX: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// compPointY: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// compPointZ: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// compRotaX: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// compRotaY: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// compRotaZ: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// compScaleX: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// compScaleY: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					// compScaleZ: {
					// 	required: true,
					// 	message: "此项不能为空",
					// 	trigger: 'blur'
					// },
					color: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					isDelete: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
				}
			};
		},
		props: {},
		watch: {
			isShow(val) {
				if (val) {
					this.open();
				}
			}
		},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				})
				if (this.dialogObj.type == 2) {
					this.get();
				}
			},
			get() {
				this.$get("/backend-api/model/unit-comp/get", {
					id: this.dialogObj.row.id
				}).then((res) => {
					if (res.code == 1000) {
						this.formData = res.data;
						this.formData.color = "#" + this.formData.color;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			submit() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.formData.unitTypeId = this.dialogObj.unitTypeId;
						if (this.formData.color) {
							this.formData.color = this.formData.color.substring(1);
						}
						this.loading = true;
						this.$postData("/backend-api/model/unit-comp/save", this.formData).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.$parent.get();
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					}
				});
			},
			focusValue() {
				this.selectGoodsDialog = true;
				setTimeout(() => {
					this.$refs.child.showView()
				}, 100)
			},
			//关闭弹窗
			close() {
				this.loading = false;
				this.visiable = false
			},
			determine() {
				let obj = this.$refs.child.valueObj
				if (obj && obj.goodsSkuId) {
					this.selectGoodsDialog = false;
					this.formData = {
						...this.formData,
						...obj
					}
				} else {
					this.$message({
						message: '没有选中任何关联商品',
						type: 'warning'
					})
				}

			},
			cancel() {
				this.selectGoodsDialog = false;
			}
		}
	};
</script>
<style lang='scss' scoped>
	.el-dialog {
		min-width: 600px;
	}
</style>