<template>
	<el-dialog class="dialog" title="新增/编辑" :visible.sync="visiable" v-if="visiable">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="140px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="关联企业：" prop="entId">
							<el-select v-model="formData.entId" clearable :popper-append-to-body="false"
								placeholder="请选择">
								<el-option v-for="item in ents" :key="item.abbr" :label="item.abbr" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="模型名称：" prop="name">
							<el-input v-model="formData.name">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="图号：" prop="number">
							<el-input v-model="formData.number">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="关联租赁商品：">
							<el-input v-model="formData.rentSkuInfo" @focus="focusValue">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="模型文件：">
							<el-upload style="display: inline-block;margin-right: 10px;" action=""
								:before-upload="BeforeUploadModal" :http-request="uploadModal" :file-list="fileList"
								:limit="1" accept=".glb,.gltf" :on-remove="onRemove">
								<el-button size="small" type="primary" :disabled="fileList.length>0">点击上传</el-button>
								<div slot="tip" class="el-upload__tip">只能上传glb/gltf文件，且不超过50mb</div>
							</el-upload>
						</el-form-item>
					</el-col>
				<!-- 	<el-col :span="12">
						<el-form-item label="模型缩放：">
							<el-input v-model="formData. modelScaling">
							</el-input>
						</el-form-item>
					</el-col> -->
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="位置：">
							<el-input v-model="formData.modelLocation" placeholder="默认0,0,0 请使用英文逗号">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="旋转：">
							<el-input v-model="formData.modelRotation" placeholder="默认0,0,0 请使用英文逗号">
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						
						<el-form-item label="缩放：">
							<el-input v-model="formData.modelScaling" placeholder="默认1,1,1 请使用英文逗号">
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
		<el-dialog title="选择商品关联模型" :visible.sync="leaseOptionDialog" width="80%" append-to-body>
			<leaseOption ref="child" style="height: 60vh;"></leaseOption>
			<div class="dialog-footer" style="display: flex;justify-content: flex-end;">
				<el-button @click="cancel()">取消关联</el-button>
				<el-button type="primary" @click="determine()">确定</el-button>
			</div>
		</el-dialog>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import leaseOption from "./leaseOption.vue";
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		components: {
			leaseOption
		},
		data() {
			return {
				title: "",
				loading: false,
				leaseOptionDialog: false,
				formData: {},
				ents: [],
				rules: {
					name: [{
						required: true,
						message: '请填写名称',
						trigger: 'change'
					}],
					entId: [{
						required: true,
						message: '请选择企业',
						trigger: 'change'
					}],
					number: [{
						required: true,
						message: '请输入图号',
						trigger: 'blur'
					}],
				},
				newFile: new FormData(),
				fileList: [],
			};
		},
		props: {},
		watch: {},
		methods: {
			onRemove() {
				this.newFile = new FormData();
				this.fileList = [];
				this.formData.unitUrl = ''
			},
			BeforeUploadModal(file) {
				if (this.fileList.length > 0) {
					this.$message.error('只能上传一份');
					return false;
				}

				if (file.size / 1024 / 1024 > 50) {
					this.$message.error('大小不可超过50MB')
					return false
				}
				if (file) {
					if (this.newFile.has("file")) {
						this.newFile.delete("file");
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
				return false;
			},
			uploadModal(file, fileList) {
				this.fileList.push(file.file);
				const loading = this.$loading({
					lock: true,
					text: '文件上传中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				this.newFile.append("type", 'model');
				this.$postData("/base-api/file/upload-with-filename", this.newFile).then((res) => {
					loading.close();
					if (res.code == 1000) {
						this.loading = false;
						this.newFile = new FormData();
						this.$message.success(res.msg);
						this.formData.unitUrl = res.data;
					} else {
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.formData.unitUrl = ''
						this.$message.error(res.msg);
					}
				}).catch((e) => {
					loading.close();
					this.$message.error('遇到问题啦，请稍后再试');
				});
			},




			//获取企业列表
			getEnts() {
				this.$get("/backend-api/sys/ent/list", {
					size: 99
				}).then((res) => {
					if (res.code == 1000) {
						this.ents = res.data.records;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开弹窗
			open() {
				this.formData = {};
				this.getEnts();
				if (this.dialogObj.type == 2) {
					console.log(this.dialogObj)
					this.get();
					this.formData = this.dialogObj.row;
					this.fileList = [];
					if (this.formData.unitUrl) {
						this.fileList.push({
							name: this.formData.unitUrl
						})
					}

				}
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				})
			},
			get() {
				// this.$get("/backend-api/model/line-comp/list", {
				// 	typeId: this.dialogObj.row.id
				// }).then((res) => {
				// 	if (res.code == 1000) {
				// 		this.formData = res.data;
				// 	} else {
				// 		this.$message.error(res.msg);
				// 	}
				// });
			},
			//提交表单
			submit() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$postData("/backend-api/model/unit/save", this.formData).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.loading = false;
								this.$message.error(res.msg);
							}
						});
					} else {
						return false;
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false;
				this.newFile = new FormData();
				this.fileList = [];
			},
			focusValue() {
				this.leaseOptionDialog = true;
				setTimeout(() => {
					this.$refs.child.showView()
				}, 100)
			},
			determine() {
				let obj = this.$refs.child.valueObj
				if (obj && obj.rentSkuId) {
					this.leaseOptionDialog = false;
					this.formData = {
						...this.formData,
						...obj
					}
				} else {
					this.$message({
						message: '没有选中任何关联商品',
						type: 'warning'
					})
				}

			},
			cancel() {
				this.leaseOptionDialog = false;
				this.formData.rentSkuInfo = '';
				this.formData.rentSkuId = '';
				this.formData.rentId = '';
			}

		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}
</style>