<template>
	<el-dialog class="dialog" :title="title+'-配置'" :visible.sync="visiable" width="95%" v-if="visiable">
		<div v-loading="loading" class="dlalog-body">
			<div style="float: right;margin: 10px 20px;">
				<el-button type="primary" @click="downloadData1">
					数据下载
				</el-button>
				<el-button type="primary" @click="downloadmodal1">
					模板下载
				</el-button>
				<el-upload style="display: inline-block;margin:0 10px;" action="" :before-upload="BeforeUpload"
					:http-request="uploadExcel1" :show-file-list="false" :file-list="fileList" :limit="1"
					accept=".xls,.xlsx">
					<el-button type="primary">导入excel</el-button>
				</el-upload>
				<el-button type="primary" @click="insert1">
					添加行
				</el-button>
				<el-button type="danger" v-if="deleteArr1.length>0" @click="deleteSelect1">
					删除选中
				</el-button>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData" border height="400"
							:header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ padding: 0 }"
							style="margin-bottom: 20px"  @selection-change="handleSelectionChange1">
							<el-table-column type="selection" width="55">
							</el-table-column>
							<el-table-column prop="id" align="center" label="id" show-overflow-tooltip width="200">
							</el-table-column>
							<el-table-column prop="name" align="center" label="零件名称" show-overflow-tooltip width="150">
							</el-table-column>
							<el-table-column prop="group" align="center" label="分区" show-overflow-tooltip width="150">
							</el-table-column>
							<el-table-column prop="compType" align="center" label="类型" show-overflow-tooltip
								width="150">
								<template slot-scope="scope">
									<div v-if="scope.row.compType==1">
										齿轮
									</div>
									<div v-else-if="scope.row.compType==2">
										滚动轴承
									</div>
									<div v-else-if="scope.row.compType==3">
										轴
									</div>
									<div v-else>
										其他
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="compTypeId" align="center" label="型号" show-overflow-tooltip
								width="150">
							</el-table-column>
							<el-table-column prop="sort" align="center" label="sort" show-overflow-tooltip width="60">
							</el-table-column>
							<el-table-column prop="goodsSkuInfo" align="center" label="关联商品" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="stlFile" align="center" label="模型文件" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>
										{{scope.row.stlFile? scope.row.stlFile.substr(scope.row.stlFile.lastIndexOf('/')+1):""}}
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="remark" align="center" label="备注" show-overflow-tooltip>
							</el-table-column>
							<el-table-column align="center" label="操作" width="400">
								<template slot-scope="scope">
									<!-- <el-upload style="display: inline-block;margin-right: 10px;" action=""
										:before-upload="BeforeUploadModal" :http-request="uploadModal" :data="scope.row"
										:show-file-list="false" :file-list="fileList" :limit="1" accept=".stl">
										<el-button type="text">上传模型</el-button>
									</el-upload> -->
									<el-upload style="display: inline-block;margin-right: 10px;" action=""
										:before-upload="BeforeUploadModal2" :http-request="uploadModal2"
										:data="scope.row" :show-file-list="false" :file-list="fileList" :limit="1"
										accept=".stl,.glb,.gltf,.jpeg,.jpg,.png">
										<el-button type="text">上传零部件</el-button>
									</el-upload>
									<el-button type="text" @click="edit2(scope.row)">
										编辑
									</el-button>
									<el-button type="text" @click="remove2(scope.row)">
										删除行
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
			<div style="float: right;margin: 10px 20px;">
				<el-button type="primary" @click="downloadData2">
					数据下载
				</el-button>
				<el-button type="primary" @click="downloadmodal2">
					模板下载
				</el-button>
				<el-upload style="display: inline-block;margin:0 10px;" action="" :before-upload="BeforeUpload"
					:http-request="uploadExcel2" :show-file-list="false" :file-list="fileList" :limit="1"
					accept=".xls,.xlsx">
					<el-button type="primary">导入excel</el-button>
				</el-upload>
				<el-button type="primary" @click="insert2">
					添加行
				</el-button>

				<el-button type="danger" v-if="deleteArr.length>0" @click="deleteSelect">
					删除选中
				</el-button>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData2" border height="300"
							:header-cell-style="{ 'background-color': '#f9fafc' }" :cell-style="{ padding: 0 }"
							style="margin-bottom: 20px" @selection-change="handleSelectionChange">
							<el-table-column type="selection" width="55">
							</el-table-column>
							<el-table-column prop="id" align="center" label="id" show-overflow-tooltip width="200">
							</el-table-column>
							<el-table-column prop="name" align="center" label="测点名称" show-overflow-tooltip width="150">
							</el-table-column>
							<el-table-column prop="modelType" align="center" label="类型" show-overflow-tooltip
								width="150">
								<template slot-scope="scope">
									<div v-if="scope.row.modelType==1">
										模型
									</div>
									<div v-else-if="scope.row.modelType==2">
										振动传感器
									</div>
									<div v-else-if="scope.row.modelType==3">
										温度传感器
									</div>
								</template>
							</el-table-column>

							<el-table-column prop="measName" align="center" label="已关联测点" show-overflow-tooltip
								width="150">
							</el-table-column>
							<el-table-column prop="sort" align="center" label="sort" show-overflow-tooltip width="70">
							</el-table-column>
							<el-table-column prop="remark" align="center" label="备注" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="stlFile" align="center" label="模型文件" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>
										{{scope.row.stlFile? scope.row.stlFile.substr(scope.row.stlFile.lastIndexOf('/')+1):""}}
									</div>
								</template>
							</el-table-column>
							<el-table-column align="center" label="操作" width="200">
								<template slot-scope="scope">
									<el-upload style="display: inline-block;margin-right: 10px;" action=""
										:before-upload="BeforeUploadModal" :http-request="uploadModal" :data="scope.row"
										:show-file-list="false" :file-list="fileList" :limit="1" accept=".stl,.glb,.gltf">
										<el-button type="text">上传模型</el-button>
									</el-upload>
									<el-button type="text" @click="edit3(scope.row)">
										编辑
									</el-button>
									<el-button type="text" @click="remove3(scope.row)">
										删除行
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
			</div>
		</div>
		<edit2 :isShow.sync="showEdit2Dialog" :dialogObj="edit2Obj"></edit2>
		<edit3 :isShow.sync="showEdit3Dialog" :dialogObj="edit3Obj"></edit3>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin";
	import configApi from "@config/configApi";
	import edit2 from "./edit2.vue";
	import edit3 from "./edit3.vue";
	import {
		Loading
	} from 'element-ui';
	export default {
		mixins: [dialogMixin],
		components: {
			edit2,
			edit3
		},
		data() {
			return {
				title: "",
				loading: false,
				formData: {},
				tableData: [],
				tableData2: [],
				showEdit2Dialog: false,
				edit2Obj: {},
				showEdit3Dialog: false,
				edit3Obj: {},
				newFile: new FormData(),
				fileList: [],
				deleteArr: [],
				deleteArr1: []
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.newFile = new FormData();
				this.tableData = [];
				this.tableData2 = [];
				if (this.dialogObj.type == 2) {
					this.get();
				}
				this.title = this.dialogObj.row.name;
			},
			get() {
				this.$get("/backend-api/model/unit-comp/list", {
					typeId: this.dialogObj.row.id
				}).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
				this.$get("/backend-api/model/unit-meas/list", {
					typeId: this.dialogObj.row.id
				}).then((res) => {
					if (res.code == 1000) {
						this.tableData2 = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//上传前校验
			BeforeUpload(file) {
				if (this.fileList.length > 0) {
					this.$message.error('只能上传一份');
					return false;
				}
				const fileType = file.name.substring(file.name.lastIndexOf('.'));
				if (fileType != '.xls' && fileType != '.xlsx') {
					this.$message.error('上传文件只能是 excel 文件格式!');
				} else if (file) {
					if (this.newFile.has("file")) {
						this.newFile.delete("file");
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
				return false;
			},
			//上传模型前校验
			BeforeUploadModal(file) {
				if (this.fileList.length > 0) {
					this.$message.error('只能上传一份');
					return false;
				}
				const fileType = file.name.substring(file.name.lastIndexOf('.'));
				if (fileType != '.stl' && fileType != '.STL') {
					this.$message.error('上传文件只能是 stl 文件格式!');
				} else
				if (file) {
					if (this.newFile.has("file")) {
						this.newFile.delete("file");
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
				return false;
			},
			BeforeUploadModal2(file) {
				if (this.fileList.length > 0) {
					this.$message.error('只能上传一份');
					return false;
				}
				if (file) {
					if (this.newFile.has("file")) {
						this.newFile.delete("file");
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
				return false;
			},
			uploadModal(file) {
				this.newFile.append("type", 0);
				this.newFile.append("id", file.data.id);
				this.$postData("/backend-api/model/stl/upload", this.newFile).then((res) => {
					if (res.code == 1000) {
						this.get();
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.success(res.msg);
					} else {
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.error(res.msg);
					}
				});
			},
			uploadModal2(file) {
				const loading = this.$loading({
					lock: true,
					text: '文件上传中...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				this.newFile.append("type", 0);
				this.newFile.append("id", file.data.id);
				this.$postData("/backend-api/model/comp-stl/upload", this.newFile).then((res) => {
					loading.close();
					if (res.code == 1000) {
						this.get();
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.success(res.msg);
					} else {
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.error(res.msg);
					}
				}).catch((e) => {
					loading.close();
					this.$message.error('遇到问题啦，请稍后再试');
				});
			},
			uploadExcel1() {
				this.newFile.append("typeId", this.dialogObj.row.id);
				this.$postData("/backend-api/model/unit-comp/upload", this.newFile).then((res) => {
					if (res.code == 1000) {
						this.get();
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.success(res.msg);
					} else {
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.error(res.msg);
					}
				});
			},
			uploadExcel2() {
				this.newFile.append("typeId", this.dialogObj.row.id);
				this.$postData("/backend-api/model/unit-meas/upload", this.newFile).then((res) => {
					if (res.code == 1000) {
						this.get();
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.success(res.msg);
					} else {
						this.loading = false;
						this.newFile = new FormData();
						this.fileList = [];
						this.$message.error(res.msg);
					}
				});
			},
			downloadData1() {
				//访问路径直接下载
				window.location.href = this.baseURL + "/backend-api/model/unit-comp/download?typeId=" + this.dialogObj.row
					.id
			},
			downloadData2() {
				//访问路径直接下载
				window.location.href = this.baseURL + "/backend-api/model/unit-meas/download?typeId=" + this.dialogObj.row
					.id
			},
			downloadmodal1() {
				// this.$get("/backend-api/model/unit-comp/template").then((res) => {
				// 	if (res.code == 1000) {
				// 		window.location.href = this.photoURL + res.data;
				// 	} else {
				// 		this.$message.error(res.msg);
				// 	}
				// });
				window.location.href = this.photoURL +'/template/new/零部件明细模板-新版-2023.12.26.xlsx'
			},
			downloadmodal2() {
				this.$get("/backend-api/model/unit-meas/template").then((res) => {
					if (res.code == 1000) {
						window.location.href = this.photoURL + res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开编辑弹窗
			edit2(row) {
				this.edit2Obj["row"] = row;
				this.edit2Obj["type"] = 2;
				this.edit2Obj["unitTypeId"] = this.dialogObj.row.id;
				this.showEdit2Dialog = true;
			},
			//打开新增弹窗
			insert1() {
				this.edit2Obj["type"] = 1;
				this.edit2Obj["unitTypeId"] = this.dialogObj.row.id;
				this.showEdit2Dialog = true;
			},
			//打开编辑弹窗
			edit3(row) {
				this.edit3Obj["row"] = row;
				this.edit3Obj["type"] = 2;
				this.edit3Obj["unitId"] = this.dialogObj.row.unitId;
				this.edit3Obj["unitTypeId"] = this.dialogObj.row.id;
				this.showEdit3Dialog = true;
			},
			//打开新增弹窗
			insert2() {
				this.edit3Obj["type"] = 1;
				this.edit3Obj["unitId"] = this.dialogObj.row.unitId;
				this.edit3Obj["unitTypeId"] = this.dialogObj.row.id;
				this.showEdit3Dialog = true;
			},
			remove2(row) {
				this.$confirm("是否确认删除？", "删除零部件", {
						dangerouslyUseHTMLString: true,
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$post("/backend-api/model/unit-comp/delete", {
							id: row.id,
						}).then((res) => {
							if (res.code == 1000) {
								this.$message.success(res.msg);
								//刷新表格
								this.get();
							} else {
								this.$message.error(res.msg);
							}
						});
					})
					.catch(() => {});
			},
			remove3(row) {
				this.$confirm("是否确认删除？", "删除测点", {
						dangerouslyUseHTMLString: true,
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$post("/backend-api/model/unit-meas/delete", {
							id: row.id,
						}).then((res) => {
							if (res.code == 1000) {
								this.$message.success(res.msg);
								//刷新表格
								this.get();
							} else {
								this.$message.error(res.msg);
							}
						});
					})
					.catch(() => {});
			},
			//关闭弹窗
			close() {
				this.visiable = false;
			},
			//多选
			handleSelectionChange(val) {
				this.deleteArr = val
			},
			//多选删除
			async deleteSelect() {

				let arr = []
				await this.deleteArr.forEach(item => {
					arr.push(item.id)
				})
				let ids = arr.join(',')
				this.$confirm("是否确认删除？", "删除选中测点", {
						dangerouslyUseHTMLString: true,
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$post('/backend-api/model/unit-meas/deleteAll', {
							ids: ids
						}).then(res => {
							if (res.code == 1000) {
								this.$message.success(res.msg);
								//刷新表格
								this.get();
							} else {
								this.$message.error(res.msg);
							}
						})
					})
					.catch(() => {});
			},
			
			//多选
			handleSelectionChange1(val) {
				this.deleteArr1 = val
			},
			//多选删除
			async deleteSelect1() {
			
				let arr = []
				await this.deleteArr1.forEach(item => {
					arr.push(item.id)
				})
				let ids = arr.join(',')
				this.$confirm("是否确认删除？", "删除选中零件", {
						dangerouslyUseHTMLString: true,
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$post('/backend-api/model/unit-comp/allDelete', {
							ids: ids
						}).then(res => {
							if (res.code == 1000) {
								this.$message.success(res.msg);
								//刷新表格
								this.get();
							} else {
								this.$message.error(res.msg);
							}
						})
					})
					.catch(() => {});
			}
		},
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss";

	.dialog {
		::v-deep.cascader {
			display: block;
		}
	}

	.el-dialog {
		min-width: 1400px;
	}
</style>